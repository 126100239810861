import React from 'react'
import './ExperienceTemplate.scss'
import { image } from '../../../app/utils/common'

const ExperienceTemplate = () => {
  return (
    <div className="experience-container">
      {/* first div */}
      <div className="exp-sub-container">
        <div className="our-experience">
          <div className="experience-headline">WE HAVE 10+ YEARS OF EXPERIENCE</div>
          <div className="our-slogan">We Are a Temple That believes in God</div>
          <div className="star-description">
            <div className="star-title">
              <img src={image.starImage} alt="Star Image" />
              <div className="exp-name">Over a Decade of Devotion</div>
            </div>
            <div className="our-description">
              We are a temple that embodies faith and reverence for God, dedicated to serving our community with love and experience.
            </div>
          </div>
          <div className="sliding-advertise">
            <div className="marq-slideone">
              <marquee direction="right">
                <span style={{ marginRight: '80px' }}>Temple Events</span>
                <span style={{ marginRight: '80px' }}>Faith-Based</span>
                <span style={{ marginRight: '80px' }}>Initiatives</span>
                <span style={{ marginRight: '80px' }}>Virtual Darshan</span>
                <span style={{ marginRight: '80px' }}>Spiritual Education</span>
                <span style={{ marginRight: '80px' }}> Hindu Rituals</span>
                <span style={{ marginRight: '80px' }}> Temple Trust</span>
              </marquee>
            </div>
            <div className="marq-slidetwo">
              <marquee direction="left">
                <span style={{ marginRight: '80px' }}>Temple Events</span>
                <span style={{ marginRight: '80px' }}>Faith-Based</span>
                <span style={{ marginRight: '80px' }}>Initiatives</span>
                <span style={{ marginRight: '80px' }}>Virtual Darshan</span>
                <span style={{ marginRight: '80px' }}>Spiritual Education</span>
                <span style={{ marginRight: '80px' }}> Hindu Rituals</span>
                <span style={{ marginRight: '80px' }}> Temple Trust</span>
              </marquee>
            </div>
            <div className="marq-slidethree">
              <marquee direction="right">
                <span style={{ marginRight: '80px' }}>Temple Events</span>
                <span style={{ marginRight: '80px' }}>Faith-Based</span>
                <span style={{ marginRight: '80px' }}>Initiatives</span>
                <span style={{ marginRight: '80px' }}>Virtual Darshan</span>
                <span style={{ marginRight: '80px' }}>Spiritual Education</span>
                <span style={{ marginRight: '80px' }}> Hindu Rituals</span>
                <span style={{ marginRight: '80px' }}> Temple Trust</span>
              </marquee>
            </div>
          </div>
        </div>

        {/* second div */}
        <div className="maharaj-image">
          <img src={image.maharaj} alt="Maharaj Image" />
        </div>
      </div>
    </div>
  )
}

export default ExperienceTemplate
