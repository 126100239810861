import './App.css'
import AppRoutes from './app/routes/AppRoutes'

function App() {
  const apiUrl = process.env.BASE_URL_LOCAL
  console.log('ghghghghgg', apiUrl)

  return (
    <div style={{ backgroundColor: '#FFFFFF', overflowX: 'hidden' }}>
      <AppRoutes />
    </div>
  )
}

export default App
