import React from 'react'
import './DownloadAppTemplate.scss'
import { image } from '../../../app/utils/common'

const DownloadAppTemplate = () => {
  const handleOnclickDownloadAndroidApp = () => {
    window.location.href = 'https://play.google.com/store/apps/details?id=com.aaradhana&pli=1'
  }

  const handleOnclickDownloadIosApp = () => {
    window.location.href = 'https://apps.apple.com/app/id6717586191'
  }

  return (
    <div className="download-app">
      <div className="download-app__container">
        <div className="download-app__header">
          <div className="download-app__title">Ready To Get Started?</div>
          <div className="download-app__subtitle">
            Join us on a transformative spiritual journey today! Sign up now to connect, contribute, and deepen your faith.
          </div>
          <div className="download-app__features">
            <div className="download-app__feature" onClick={handleOnclickDownloadIosApp}>
              Download App <img src={image.AppleIcon} alt="Apple Store" />
            </div>
            <div className="download-app__feature" onClick={handleOnclickDownloadAndroidApp}>
              Download App <img src={image.playstoreIcon} alt="Google Play Store" />
            </div>
          </div>
          <img src={image.bottomcircle} alt="Decorative Bottom Circle" className="bottom-circle" />
        </div>
        <div className="download-app__images">
          <img src={image.footerimageone} alt="iPhone 1" className="download-app__imageone" />
          <img src={image.footerimagetwo} alt="iPhone 2" className="download-app__imagetwo" />
          <img src={image.uppercircle} alt="Upper Circle" className="upper-circle" />
          <div className="DownloadAppTemplate-shadow"></div>
          <img src={image.DarkStar} alt="Decorative Star" className="lite-star-two-dat" />
        </div>
      </div>
    </div>
  )
}

export default DownloadAppTemplate
