import { createSlice } from '@reduxjs/toolkit'
import { getDonationPlans, getPoojaPlans } from '../../ApiCalls/Home/landingPage'

const initialState = {
  poojaData: [],
  donationData: [],
  loading: false,
  error: null,
  status: null,
  message: null,
  count: 0,
  limit: 10,
  page: 1,
}

const landingPageSlice = createSlice({
  name: 'landingPage',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getPoojaPlans.pending, (state) => {
        state.loading = true
        state.error = null
      })
      .addCase(getPoojaPlans.fulfilled, (state, action) => {
        state.loading = false
        state.status = action.payload.status
        state.message = action.payload.message
        state.poojaData = action.payload.data.data
        state.count = action.payload.count
        // console.log('payload', action)
      })
      .addCase(getPoojaPlans.rejected, (state, action) => {
        state.loading = false
        state.error = action.error.message
      })
      .addCase(getDonationPlans.pending, (state) => {
        state.loading = true
        state.error = null
      })
      .addCase(getDonationPlans.fulfilled, (state, action) => {
        state.loading = false
        state.status = action.payload.status
        state.message = action.payload.message
        state.donationData = action.payload.data.data
        state.count = action.payload.data.length
        // console.log('donationData', state.donationData)
      })
      .addCase(getDonationPlans.rejected, (state, action) => {
        state.loading = false
        state.error = action.error.message
      })
  },
})

export default landingPageSlice.reducer
