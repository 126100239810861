import React from 'react'
import { Route, Routes } from 'react-router-dom'
import Homepage from '../../components/organisms/homepage/homepage'
import Footer from '../../components/molecules/Footer/Footer'
import PrivacyPolicy from 'components/molecules/PrivacyPolicy/PrivacyPolicy'

const AppRoutes = () => {
  return (
    <>
      <Routes>
        <Route path="/" element={<Homepage />} />
        <Route path="/Privacy" element={<PrivacyPolicy />} />
      </Routes>
      <Footer />
    </>
  )
}

export default AppRoutes
