import React, { forwardRef, useEffect, useState } from 'react'
import './DonateToHelpTemp.scss'
import { useDispatch, useSelector } from 'react-redux'
import { getDonationPlans } from '../../../Redux/ApiCalls/Home/landingPage'
import { FaAngleDoubleLeft, FaAngleDoubleRight } from 'react-icons/fa'
import DonationCard from 'components/atom/donationCard/donationCard'

const DonateToHelpTemp = forwardRef((props, ref) => {
  const dispatch = useDispatch()
  const [currentIndex, setCurrentIndex] = useState(0)
  const itemsPerPage = 2

  const { donationData, loading } = useSelector((state) => state.landingPage)

  useEffect(() => {
    dispatch(getDonationPlans({ limit: 10, page: 1 }))
  }, [])

  const handleNext = () => {
    setCurrentIndex((prevIndex) => Math.min(prevIndex + itemsPerPage, donationData.length))
  }

  const handlePrev = () => {
    setCurrentIndex((prevIndex) => Math.max(prevIndex - itemsPerPage, 0))
  }

  return (
    <div id="donations" ref={ref} className="donatehelp-container">
      <div className="donatehelp-content">
        <div className="donatehelp-title">DONATE TO HELP</div>
        <div className="donatehelp-subtitle">Make Donation to Help Community</div>
        <div className="donatehelp-cardcontainer">
          <div className="dummy-div" style={{ transform: `translateX(-${(currentIndex / itemsPerPage) * 100}%)` }}>
            {donationData && donationData.length > 0 ? (
              donationData.map((item, index) => <DonationCard key={index} item={item} />)
            ) : (
              <div className="no-donation-available">No donations available.</div>
            )}
          </div>
          {donationData.length > 0 ? (
            <div className="swipe-buttons">
              <FaAngleDoubleLeft size={'3.604vw'} color="orange" onClick={handlePrev} />
              <FaAngleDoubleRight size={'3.604vw'} color="orange" onClick={handleNext} />
            </div>
          ) : (
            ''
          )}
        </div>
      </div>
    </div>
  )
})

export default DonateToHelpTemp
