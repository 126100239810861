import React, { forwardRef } from 'react'
import './TempleTemplate.scss'
import { image } from '../../../app/utils/common'
import Header from '../Header/Header'

const TempleTemplate = forwardRef((props, ref) => {
  return (
    <div id="home" ref={ref} className="homepage-container">
      <Header />
      <div className="home-hero">
        <img src={image.temple} alt="" />
      </div>
      <div className="headline-qr">
        <div className="main-headline">
          <span className="line">A Digital Platform</span>
          <span className="line">to Connect with your</span>
          <span className="line">Temple/Trusts</span>
        </div>
        <div className="qr-downloadapp">
          <div className="qr-code">
            <img src={image.qrCode} alt="QR Code" />
          </div>
          <div className="download-aaradhana">
            <span>Download</span>
            <span>Aradhanaa App</span>
          </div>
        </div>
      </div>
    </div>
  )
})

export default TempleTemplate
