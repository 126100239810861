import React, { useEffect, useState } from 'react'
import './Header.scss'
import { image } from '../../../app/utils/common'
import { GiHamburgerMenu } from 'react-icons/gi'

const Header = () => {
  const [activeSection, setActiveSection] = useState('home')
  const [isMenuOpen, setMenuOpen] = useState(false)
  const [isSticky, setSticky] = useState(false)

  const handleScroll = () => {
    const scrollTop = window.scrollY
    setSticky(scrollTop > 60)
  }

  const handleClickOfDownloadApp = () => {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera

    if (/android/i.test(userAgent)) {
      window.location.href = 'https://play.google.com/store/apps/details?id=com.aaradhana&pli=1'
    } else if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      window.location.href = 'https://apps.apple.com/in/app/aradhanaa/id6717586191'
    } else {
      window.location.href = 'https://play.google.com/store/apps/details?id=com.aaradhana&pli=1'
    }
  }

  const toggleMenu = () => {
    setMenuOpen(!isMenuOpen)
  }
  const closeMenu = () => {
    setMenuOpen(false)
  }

  const scrollToSection = (id) => {
    const section = document.getElementById(id)
    if (section) {
      section.scrollIntoView({ behavior: 'smooth' })
      setActiveSection(id)
    }
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll)
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  return (
    <div className={`header-container ${isSticky ? 'sticky' : ''}`}>
      <header className={`header ${isSticky ? 'sticky' : ''}`}>
        <div className="aaradhana-logo">
          <img src={image.logo} alt="Aaradhana Logo" />
        </div>
        <div className="menu-icon" onClick={toggleMenu}>
          <GiHamburgerMenu size={38} />
        </div>
        <nav className={`aaradhana-navbar ${isMenuOpen ? 'open' : ''}`} aria-label="Main Navigation">
          <ul>
            <li onClick={() => scrollToSection('home')} className={activeSection === 'home' ? 'active' : ''}>
              <a href="#home" onClick={closeMenu}>
                Home
              </a>
            </li>
            <li onClick={() => scrollToSection('donations')} className={activeSection === 'donations' ? 'active' : ''}>
              <a href="#donations" onClick={closeMenu}>
                Donations
              </a>
            </li>
            <li onClick={() => scrollToSection('poojas')} className={activeSection === 'poojas' ? 'active' : ''}>
              <a href="#poojas" onClick={closeMenu}>
                Poojas
              </a>
            </li>
            <li onClick={() => scrollToSection('testimonials')} className={activeSection === 'testimonials' ? 'active' : ''}>
              <a href="#testimonials" onClick={closeMenu}>
                Testimonials
              </a>
            </li>
            <button onClick={handleClickOfDownloadApp}>Download App</button>
          </ul>
        </nav>
      </header>
    </div>
  )
}

export default Header
