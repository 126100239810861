import React, { useEffect, useState } from 'react'
import './CircularTestimonial.scss'
import { image } from 'app/utils/common'

const images = [image.testmonialone, image.testmonialfour, image.testmonialtwo, image.testmonialthree]

const CircularTestimonial = () => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0)

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length)
    }, 3000)

    return () => clearInterval(interval)
  }, [])

  return (
    <div className="main-container" aria-label="Circular Testimonial Images">
      <div className="circle">
        {images.map((img, index) => {
          const angle = (index / images.length) * (Math.PI * 2)
          const x = Math.cos(angle) * 90
          const y = Math.sin(angle) * 90

          return (
            <div
              key={index}
              className="profile-image"
              style={{
                backgroundImage: `url(${img})`,
                transform: `translate(${x}px, ${y}px)`,
              }}
              role="img"
              aria-label={`Profile image ${index + 1}`}
            />
          )
        })}
      </div>
      <div className="center-image" style={{ backgroundImage: `url(${images[currentImageIndex]})` }} role="img" aria-label="Current center image" />
    </div>
  )
}

export default CircularTestimonial
