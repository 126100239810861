import React from 'react'

const DateFormatter = ({ dateString }) => {
  const formatDate = (date) => {
    const options = { year: 'numeric', month: 'long', day: 'numeric' }
    return new Date(date).toLocaleDateString('en-US', options)
  }

  return <span>{formatDate(dateString)}</span>
}

export default DateFormatter
