import './BookPujaTemplate.scss'
import React, { forwardRef, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getPoojaPlans } from '../../../Redux/ApiCalls/Home/landingPage'
import { FaAngleDoubleRight, FaAngleDoubleLeft } from 'react-icons/fa'
import PoojaCard from 'components/atom/poojaCard/poojaCard'

export const BookPujaTemplate = forwardRef((props, ref) => {
  const [currentIndex, setCurrentIndex] = useState(0)
  const itemsPerPage = 4
  const dispatch = useDispatch()

  const { poojaData, loading } = useSelector((state) => state.landingPage)

  useEffect(() => {
    const fetchPoojaPlans = () => {
      dispatch(getPoojaPlans({ limit: 10, page: 1 }))
    }
    fetchPoojaPlans()
  }, [])

  const handleNext = () => {
    setCurrentIndex((prevIndex) => Math.min(prevIndex + itemsPerPage, poojaData.length))
  }

  const handlePrev = () => {
    setCurrentIndex((prevIndex) => Math.max(prevIndex - itemsPerPage, 0))
  }

  return (
    <div id="poojas" ref={ref} className="bookpuja-container">
      <div className="pujarti-container">
        <div className="puja-aarti">POOJA / AARTI</div>
        <div className="book-puja-aarti">
          <span>Book Poojas with </span>
          <span>Temples</span>
        </div>
        <div className="simplify-rituals">
          <span>Simplify your spiritual rituals by booking poojas and religious ceremonies through the app.</span>
          <span>Choose from a variety of pooja options and make your offerings with ease.</span>
        </div>
      </div>

      <div className="pooja-cards-container">
        <div className="pooja-cards" style={{ transform: `translateX(-${(currentIndex / itemsPerPage) * 100}%)` }}>
          {poojaData && poojaData.length > 0 ? (
            poojaData.map((pooja) => <PoojaCard key={poojaData.id} pooja={pooja} />)
          ) : (
            <div className="no-pooja-cards">No Pooja Plans Available</div>
          )}
        </div>
        {poojaData && poojaData.length > 0 && (
          <div className="swipe-buttons">
            <FaAngleDoubleLeft size={'3.604vw'} color="orange" onClick={handlePrev} />
            <FaAngleDoubleRight size={'3.604vw'} color="orange" onClick={handleNext} />
          </div>
        )}
      </div>
    </div>
  )
})

export default BookPujaTemplate
