import React from 'react'
import './donationCard.scss' // Create a corresponding SCSS file if needed
import { image } from '../../../app/utils/common'

const DonationCard = ({ item }) => {
  return (
    <div className="donatehelp-card">
      <div className="donatehelp-image">
        <img src={item.image || image.noImageAvailable} alt="Donation Banner" />
      </div>
      <div className="donatehelp-card-info">
        <div className="donatehelp-heading">{item.title || 'Become those who do'}</div>
        <div className="donatehelp-description">{item.description || 'Lorem Ipsum is simply dummy text...'}</div>
        <div className="donatehelp-stats">
          <div className="donatehelp-raised">Amount: Rs.{item.amount || 0}</div>
        </div>
        <div className="donatehelp-button-container">
          <button className="donatehelp-button">Donate Now</button>
        </div>
      </div>
    </div>
  )
}

export default DonationCard
